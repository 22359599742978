import LeonCasino from '../img/leon.png';


export const BestOffer =
{
    titel: 'LeonCasino',
    img: LeonCasino,
    link: 'https://clcr.me/B60cil',
    offer1: '',
    offer2: '',
    offer3: '150% Bonus up to 1500€',
    offer4: 'Cashback + FS',
    offer5: 'Wager 30x',
    "MGA": false,
    "CUR": true,
    "CR": false,
    "width": 250,
    "height":250,
    "NEW": true,
}

