export const YouTube = [
    {
        title: "INSANE 1660X RAW HIGHSTAKE! Twitch Highlights #32",
        url: "https://www.youtube.com/embed/tq8nSRadZ-A"
    },
    {
        title:"JOKER BOMBS & DAS X! Twitch Highlights #31",
        url:"https://www.youtube.com/embed/rzTpYa4twAg"
    },{
        title: "INSANE 1000X RAW CASH! Twitch Highlights #30",
        url: "https://www.youtube.com/embed/KwUFfbtjI_U"
    },{
        title: "ICE DRAGON 1500€ WIN! Twitch Highlights #29",
        url: "https://www.youtube.com/embed/iXPIrXp_qKo"
    }

    
]
