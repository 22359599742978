import React from "react";
import { YouTube } from '../list/YouTube.jsx';

const Youtube = () => {

    return (
        <div className='outer_youtube_box' data-aos='fade-up'>
            <div>
                <p className='video_text'>Latest videos</p>
            </div>

            <div className='youtube_container'>

                {YouTube.map((video, index) => (
                    <div className='youtube_div' key={index}>
                        <iframe className='youtube_iframe' src={video.url}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Youtube;